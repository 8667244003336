
import AddNewWizard from '@/pages/data-sources/add-devices/AddNewWizard.vue';
import DataSourcesCamerasTable from '@/pages/data-sources/DataSourcesCamerasTable.vue';
import ListPage from '@/pages/ListPage.vue';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import { Camera, VideoArchive } from '@/api';
import { CamerasFilter } from '@/api/models/CamerasFilter';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { MultisidebarItemType, MultisidebarItemTypes } from '@/store/multisidebar/types';
import { Options, Vue } from 'vue-class-component';
import { PagePaths, PageState, PageType, PageTypes } from '@/store/application/page.definitions';
import { Prop, Watch } from 'vue-property-decorator';
import { VideosFilter } from '@/api/models/VideosFilter';
import { aclModule } from '@/store/acl';
import { actionHandler } from '@/store/data/ActionHandler';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { multisidebarModule } from '@/store/multisidebar';
import { pageModule } from '@/store/application/page.module';
import { GlobalEvent } from '@/store/global-event/types';
import { merge } from 'lodash';
import DataSourceVideosTable from '@/pages/data-sources/DataSourceVideosTable.vue';
import { uploadModule } from '@/store/application/upload.module';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { OptionalIFormLayout } from '@/components/common/filter/filters/types';
import { MultisidebarCommonItem } from '@/store/multisidebar/types';
import EntityBlank from '@/components/common/EntityBlank.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import { getDataSourceCamerasFilter } from '@/pages/data-sources/dataSourceFilterBuilder';
import ModulePageNavigation from '@/components/common/ModulePageNavigation.vue';

type ModuleItem = Camera | VideoArchive;
type ModuleFilter = CamerasFilter | VideosFilter;

function isVideo(item: ModuleItem): item is VideoArchive {
  return typeof (item as VideoArchive).finished !== 'undefined';
}

function filterSidebarItemByPageType(item: MultisidebarCommonItem, pageType: PageType) {
  switch (pageType) {
    case PageTypes.Videos:
      return item.type === MultisidebarItemTypes.Videos;
    case PageTypes.Cameras:
    case PageTypes.ExternalDetectors:
      return item.type === MultisidebarItemTypes.Cameras;
    default:
      return false;
  }
}

function filterItemByPageType(item: ModuleItem, pageType: PageType) {
  if (isVideo(item)) return true;

  switch (pageType) {
    case PageTypes.ExternalDetectors:
      return item.external_detector;
    case PageTypes.ExternalVmsCameras:
    case PageTypes.Cameras:
    default:
      return !item.external_detector;
  }
}

@Options({
  name: 'DataSourcesPage',
  components: {
    ModulePageNavigation,
    FilterSection,
    EntityBlank,
    AddNewWizard,
    ListPage,
    NBaseBar,
    NButton,
    NButtonGroup,
    SortDropdown,
    Statistics
  }
})
export default class DataSourcesPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  protected wizardEnabled = false;

  get tableComponent() {
    return this.state.pageType === PageTypes.Videos ? DataSourceVideosTable : DataSourcesCamerasTable;
  }

  get smallFiltersLayout(): OptionalIFormLayout[] {
    return getDataSourceCamerasFilter({ small: true, pageType: this.state.pageType });
  }

  get bigFiltersLayout(): OptionalIFormLayout[] {
    return getDataSourceCamerasFilter({ small: false, pageType: this.state.pageType });
  }

  get state(): PageState {
    return pageModule.getPageStateByTab(PagePaths.DataSources, this.tab);
  }

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get createIcon() {
    return this.state.pageType === PageTypes.Videos ? 'upload' : 'add';
  }

  get createLabel() {
    return this.state.pageType === PageTypes.Videos ? this.$t('ds.upload', 'f') : this.$t('ds.create', 'f');
  }

  get sortTypes(): any[] {
    // todo: add when back will be ready: {name: true, state: true}
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get pageTypeItems() {
    const items = [
      {
        name: MultisidebarItemTypes.Cameras,
        i18n_label: 'ds.cameras',
        permissions: 'ffsecurity.view_camera'
      },
      {
        name: MultisidebarItemTypes.Videos,
        i18n_label: 'ds.uploads',
        permissions: 'ffsecurity.view_videoarchive'
      },
      {
        name: MultisidebarItemTypes.ExternalDetectors,
        i18n_label: 'ds.external_detectors',
        permissions: 'ffsecurity.view_camera'
      }
    ];
    return items.filter((i) => aclModule.getAccess(i.permissions)).map((v) => ({ name: v.name, label: this.$t(v.i18n_label, 'f') }));
  }

  get modelAcl() {
    this.module.aclModelName = this.state.pageType === PageTypes.Videos ? 'videoarchive' : 'camera';
    return aclModule.getModelAcl<ModuleItem, ModuleFilter>(this.module);
  }

  get sidebarType() {
    switch (this.state.pageType) {
      case PageTypes.Videos:
        return MultisidebarItemTypes.Videos;
      case PageTypes.Cameras:
        return MultisidebarItemTypes.Cameras;
      case PageTypes.ExternalDetectors:
        return MultisidebarItemTypes.Cameras;
      case PageTypes.ExternalVmsCameras:
      default:
        return MultisidebarItemTypes.Cameras;
    }
  }

  get sidebarSelectedItemIds(): number[] {
    return this.sidebarModule.items
      .filter((v) => filterSidebarItemByPageType(v, this.state.pageType))
      .filter((v) => filterItemByPageType(v.model.item as ModuleItem, this.state.pageType))
      .map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === this.sidebarType ? globalEventModule.current : null;
  }

  get hasReport() {
    const pagesHaveReport: MultisidebarItemType[] = [MultisidebarItemTypes.Cameras, MultisidebarItemTypes.ExternalDetectors];
    return pagesHaveReport.includes(this.state.pageType as any as MultisidebarItemType);
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.module.get();
        this.module.getStatistics();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  @Watch('state.pageType', { immediate: true })
  setForceFilters() {
    const force: Record<string, any> = {};
    if (this.state.pageType === PageTypes.Cameras) force.external_detector = false;
    if (this.state.pageType === PageTypes.ExternalDetectors) force.external_detector = true;
    if (this.module) {
      this.module.filter.force = force;
    }
  }

  @Watch('state.pageType', { immediate: false })
  syncVideosUploaderModule() {
    if (this.state.pageType === PageTypes.Videos) {
      uploadModule.registerClient(this.tab, this.module);
      uploadModule.syncClient(this.tab);
    } else {
      uploadModule.unregisterClient(this.tab);
    }
  }

  @Watch('module.filter.current', { deep: true })
  handleFilterStateChange(v: any, p: any): void {
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.module.debouncedGet();
  }

  created() {
  }

  mounted() {
    this.syncVideosUploaderModule();
  }

  beforeUnmount() {
    uploadModule.unregisterClient(this.tab);
  }

  async uploadHandler({ urls, attachments, params }: any) {
    const config: Record<string, number | boolean> = params;
    const parameters = await cameraResetModule.getSettings();
    const results: VideoArchive[] = [];
    this.wizardEnabled = false;

    results.push(...(await uploadModule.createVideos(this.tab, urls, config, parameters)));
    results.push(...(await uploadModule.uploadVideos(this.tab, attachments, config, parameters)));

    if (results.length) {
      await this.module.get();
      params.open && (await multisidebarModule.addItemAndOpen(MultisidebarItemTypes.Videos, results[0]));
      await multisidebarModule.addItems(MultisidebarItemTypes.Videos, results);
      await this.module.get();
      await this.module.getStatistics();
    }
  }

  enableWizard() {
    this.wizardEnabled = true;
  }

  handleSelect(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
      });
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }


  closeWizard() {
    this.wizardEnabled = false;
    this.module.get();
  }
}
