import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "settings-ui-item" }
const _hoisted_2 = { class: "label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NTooltip = _resolveComponent("NTooltip")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('common.display_lists_by_default', 'f')) + ":", 1),
        _createElementVNode("span", { ref: _ctx.referenceChange }, [
          _createVNode(_component_NButtonGroup, {
            items: _ctx.dataAssetsModule.displayTypes,
            modelValue: _ctx.currentListView,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentListView) = $event)),
            "extra-paddings": ""
          }, null, 8, ["items", "modelValue"])
        ], 512),
        _createVNode(_component_NTooltip, {
          reference: _ctx.reference,
          placement: "bottom",
          mode: "hover"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('settings.display_type_tooltip')), 1)
          ]),
          _: 1
        }, 8, ["reference"])
      ])
    ]),
    _: 1
  }))
}