
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { applicationModule } from '@/store/application';
import { DisplayType } from '@/store/application/page.definitions';
import NTooltip from '@/uikit/hint/NTooltip.vue';

@Options({
  name: 'InterfaceSettingsPage',
  components: { NTooltip, NButtonGroup, SettingsPageLayout }
})
export default class InterfaceSettingsPage extends Vue {
  private currentListView = DisplayType.Short;

  reference: any = null;
  referenceChange(reference: any) {
    this.reference = reference;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get applicationModule() {
    return applicationModule;
  }

  async created() {
    this.currentListView = applicationModule.settings.ui.list_view;
  }

  @Watch('currentListView')
  currentListViewHandler(v: any) {
    this.applicationModule.toggleListView(v);
  }
}
