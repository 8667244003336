
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { NewItemIdStart } from '@/store/multisidebar';
import NButton from '@/uikit/buttons/NButton.vue';
import { ListViewModel } from '@/definitions/view-models';
import { getPageFromNextPage } from '@/definitions/common/base';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { NButtonGroupItem } from '@/uikit/buttons/types';

@Options({
  name: 'ModulePageNavigation',
  components: { NButtonGroup, NButton }
})
export default class ModulePageNavigation extends Vue {
  @Prop({ type: Object, required: true })
  readonly module!: ListViewModel<any, any>;

  get items(): NButtonGroupItem[] {
    const firstPage = !this.module.prevPages.length;
    const hasNextPage = !!this.module.next_page;

    const result = [
      { icon: 'navigation-double-left', label: '', value: 'first', disabled: firstPage },
      { icon: 'navigation-left', label: '', value: 'prev', disabled: firstPage },
      { icon: 'navigation-refresh', label: this.$t('common.page', 'f') + ' ' + this.currentPage, value: 'refresh' },
      { icon: 'navigation-right', label: '', value: 'next', disabled: !hasNextPage }
    ];
    return result;
  }

  get currentPage() {
    return this.module.prevPages.length + 1;
  }

  @Watch('module.filter.current', { deep: true })
  filterChangeHandler(v: any, p: any) {
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.resetPage();
  }

  resetPage() {
    this.module.prevPages = [];
    this.module.filter.current.page = '';
  }

  async navigate(type: string) {
    switch (type) {
      case 'first':
        this.resetPage();
        break;
      case 'prev':
        this.module.filter.current = { ...this.module.filter.current, page: this.module.prevPages.pop() };
        break;
      case 'next':
        this.module.prevPages.push(this.module.filter.current.page);
        this.module.filter.current = { ...this.module.filter.current, page: this.module.next_page ? getPageFromNextPage(this.module.next_page) : null };
        break;
      case 'refresh':
        break;
    }
    await this.module.get();
  }
}
