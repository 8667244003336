import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_SortDropdown = _resolveComponent("SortDropdown")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_ModulePageNavigation = _resolveComponent("ModulePageNavigation")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_AddNewWizard = _resolveComponent("AddNewWizard")!
  const _component_EntityBlank = _resolveComponent("EntityBlank")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_openBlock(), _createBlock(_component_ListPage, {
    class: "data-sources-page",
    state: _ctx.state,
    statistics: ""
  }, {
    filters: _withCtx(() => [
      _createVNode(_component_FilterSection, {
        filter: _ctx.module.filter,
        "scheme-small": _ctx.smallFiltersLayout,
        "scheme-large": _ctx.bigFiltersLayout,
        "has-pinned-fields": "",
        "has-report": _ctx.hasReport
      }, {
        small: _withCtx(() => [
          _createVNode(_component_NButtonGroup, {
            items: _ctx.pageTypeItems,
            modelValue: _ctx.state.pageType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.pageType) = $event))
          }, null, 8, ["items", "modelValue"])
        ]),
        _: 1
      }, 8, ["filter", "scheme-small", "scheme-large", "has-report"])
    ]),
    statistic: _withCtx(() => [
      _createVNode(_component_NBaseBar, null, {
        start: _withCtx(() => [
          _createVNode(_component_Statistics, { module: _ctx.module }, null, 8, ["module"]),
          _createVNode(_component_SortDropdown, {
            items: _ctx.sortTypes,
            modelValue: _ctx.module.filter.current.ordering,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module.filter.current.ordering) = $event))
          }, null, 8, ["items", "modelValue"])
        ]),
        end: _withCtx(() => [
          (_ctx.modelAcl.add)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                onAction: _ctx.enableWizard,
                icon: _ctx.createIcon,
                type: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.createLabel), 1)
                ]),
                _: 1
              }, 8, ["onAction", "icon"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ModulePageNavigation, { module: _ctx.module }, null, 8, ["module"])
        ]),
        _: 1
      })
    ]),
    items: _withCtx(() => [
      (_ctx.module.items.length !== 0)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tableComponent), {
            key: 0,
            items: _ctx.module.items,
            "selected-items": _ctx.sidebarSelectedItemIds,
            "model-acl": _ctx.modelAcl,
            onSelect: _ctx.handleSelect
          }, null, 8, ["items", "selected-items", "model-acl", "onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.wizardEnabled)
        ? (_openBlock(), _createBlock(_component_AddNewWizard, {
            key: 1,
            modelValue: this.state.pageType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.state.pageType) = $event)),
            onUpload: _ctx.uploadHandler,
            onClose: _ctx.closeWizard
          }, null, 8, ["modelValue", "onUpload", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    "items-empty": _withCtx(() => [
      (_ctx.module.loaded && !_ctx.module.loading && _ctx.module.items.length === 0)
        ? (_openBlock(), _createBlock(_component_EntityBlank, {
            key: 0,
            "page-prefix": "ds",
            "page-type": _ctx.state.pageType,
            "create-icon": _ctx.state.pageType === 'videos' ? 'upload' : 'add',
            "has-create": _ctx.modelAcl.add,
            "is-empty": !_ctx.module.filter.hasChanges,
            onCreate: _ctx.enableWizard
          }, null, 8, ["page-type", "create-icon", "has-create", "is-empty", "onCreate"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["state"]))
}