import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SortSection = _resolveComponent("SortSection")!
  const _component_EntityBlank = _resolveComponent("EntityBlank")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_FilterSection, {
        filter: _ctx.module.filter,
        schemeSmall: _ctx.smallFiltersLayer,
        schemeLarge: _ctx.largeFiltersLayer,
        "has-pinned-fields": ""
      }, null, 8, ["filter", "schemeSmall", "schemeLarge"]),
      _createVNode(_component_SortSection, { module: _ctx.module }, {
        after: _withCtx(() => [
          _createVNode(_component_NButton, {
            type: "secondary",
            icon: "add",
            onClick: _ctx.addNewCounter
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('counters.create_counters')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["module"]),
      (_ctx.module.loaded && !_ctx.module.loading && _ctx.module.items.length === 0)
        ? (_openBlock(), _createBlock(_component_EntityBlank, {
            key: 0,
            "page-prefix": "counters",
            "page-type": _ctx.state.pageType,
            "create-icon": "counters",
            "has-create": _ctx.modelAcl.add,
            "is-empty": !_ctx.module.filter.hasChanges,
            onCreate: _ctx.addNewCounter
          }, null, 8, ["page-type", "has-create", "is-empty", "onCreate"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_NTable, {
              class: "counters-page__table",
              columns: _ctx.columns,
              content: _ctx.module.items,
              "selected-items-ids": _ctx.selectedItemIds,
              disabled: !_ctx.modelAcl.update,
              onAction: _ctx.handleAction,
              onCellClick: _ctx.cellClickHandler,
              "onSelect:changes": _ctx.handleSelectChanges
            }, null, 8, ["columns", "content", "selected-items-ids", "disabled", "onAction", "onCellClick", "onSelect:changes"]),
            _createVNode(_component_InfiniteScroll, {
              filters: _ctx.module.filter.current,
              onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
            }, null, 8, ["filters"])
          ], 64))
    ]),
    _: 1
  }))
}