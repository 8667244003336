export default {
  display_type_tooltip:
    "Sets the default display type in Search, Video Wall, Events and Episodes, Card Index. \nIt is possible to display type as lists or tiles.",
  vms_cleaner_desc:
    'For the service to work correctly, make sure that the "vms_cleanup" parameter is enabled in the findface-multi-legacy.py configuration file',
  delete_full_frames_of_matched_events_older_than: 'Delete full frames of matched events older than',
  delete_full_frames_of_unmatched_events_older_than: 'Delete full frames of unmatched events older than',
  delete_matched_events_older_than: 'Delete matched events older than',
  delete_unmatched_events_older_than: 'Delete unmatched events older than',
  events_cleanup: 'Events cleanup',
  generic_confidence_threshold: 'Generic confidence threshold',
  hours: 'hours',
  thumbnail_jpeg_quality: 'Thumbnail JPEG quality',
  update: 'update',
  days: 'days',
  seconds: 'sec',
  cleanup_archive_header: 'Clean up archive',
  custom_cleanup_archive: 'Enable custom cleanup',
  custom_cleanup_archive_desc: 'Enable custom archive cleanup settings. By default, global settings are used.',
  cleanup_archive: 'Clean up archive',
  cleanup_archive_desc: 'Enable the entire video archive cleanup',
  archive_cleanup_age: 'Archive cleanup age',
  archive_cleanup_age_desc: 'A number of days the video archive is kept in the system. After that period, the video archive is removed.',
  vms_cleanup_settings: 'VMS cleanup settings',
  between_tracks_cleanup_header: 'Clean up between tracks',
  custom_cleanup_between_tracks: 'Enable custom cleanup',
  custom_cleanup_between_tracks_desc: 'Enable custom clean up between tracks’ settings. By default, global settings are used.',
  cleanup_between_tracks: 'Clean up between tracks',
  cleanup_between_tracks_desc: 'Clean up those video intervals between tracks that do not contain recognition events.',
  keep_event_types: 'Save tracks of events',
  keep_event_types_desc: 'Save tracks of events for a selected object.',
  keep_event_before_after: 'Keep events before and after',
  keep_event_before_after_desc: 'A video interval in seconds before and after a track with events that is kept along with the track.',
  cleanup_threshold: 'Cleanup threshold',
  cleanup_threshold_desc: 'A minimum interval between tracks in seconds. If an interval between tracks is below the threshold, the video is not cleaned up.',
  between_tracks_cleanup_start_age: 'Clean up between tracks from',
  between_tracks_cleanup_start_age_desc:
    'Time period (in days) during which the entire video archive is kept. After this period, the intervals between tracks will be cleaned up as configured in the settings. The value can be greater than or equal to zero.',
  display_default_settings: 'Display global settings'
};
