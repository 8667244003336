export default {
  display_type_tooltip:
    "Устанавливает отображение по-умолчанию в разделах Поиск, Видеостена, События и эпизоды, Картотека. \nВозможно отображение в виде списков или плиток.",
  vms_cleaner_desc: 'Для корректной работы сервиса, проверьте что в конфигурационном файле findface-multi-legacy.py включен параметр "vms_cleanup"',
  delete_full_frames_of_matched_events_older_than: 'Удалять полные кадры событий с совпадениями старше',
  delete_full_frames_of_unmatched_events_older_than: 'Удалять полные кадры событий без совпадений старше',
  delete_matched_events_older_than: 'Удалять события с совпадениями старше',
  delete_unmatched_events_older_than: 'Удалять события без совпадений старше',
  events_cleanup: 'Очистка событий',
  generic_confidence_threshold: 'Универсальный порог срабатывания',
  hours: 'часов',
  thumbnail_jpeg_quality: 'Качество JPEG миниатюр',
  update: 'обновить',
  days: 'дни',
  seconds: 'сек',
  cleanup_archive_header: 'Очищать архив',
  custom_cleanup_archive: 'Включить индивидуальные настройки',
  custom_cleanup_archive_desc: 'Включить индивидуальные настройки очистки архива. По умолчанию используются глобальные настройки.',
  cleanup_archive: 'Очищать архив',
  cleanup_archive_desc: 'Включить очищение всего видеоархива',
  archive_cleanup_age: 'Удалять данные старше',
  archive_cleanup_age_desc: 'Количество дней, в течение которых видеоархив хранится в системе. По истечении этого срока видеоархив удаляется.',
  vms_cleanup_settings: 'Настройки очистки VMS',
  between_tracks_cleanup_header: 'Удалять интервалы между треками',
  custom_cleanup_between_tracks: 'Включить индивидуальные настройки',
  custom_cleanup_between_tracks_desc: 'Включить индивидуальные настройки удаления интервалов между треками. По умолчанию используются глобальные настройки.',
  cleanup_between_tracks: 'Удалять интервалы между треками',
  cleanup_between_tracks_desc: 'Удалять промежутки видео между треками, не содержащие событий распознавания.',
  keep_event_types: 'Сохранять события',
  keep_event_types_desc: 'Сохранять события для выбранного объекта.',
  keep_event_before_after: 'Добавить отступы к треку',
  keep_event_before_after_desc: 'Промежуток видео в секундах до и после трека с событиями, который добавляется к треку.',
  cleanup_threshold: 'Порог очистки',
  cleanup_threshold_desc: 'Минимальный промежуток между треками в секундах. Если промежуток между треками ниже порога, видео не будет удалено.',
  between_tracks_cleanup_start_age: 'Удалять интервалы между треками через',
  between_tracks_cleanup_start_age_desc:
    'Временной период (в днях), в течение которого хранится весь видеоархив. По истечении этого периода начнется очистка интервалов между треками так, как она задана в настройках. Значение может быть больше либо равно нулю.',
  display_default_settings: 'Показать глобальные настройки'
};
