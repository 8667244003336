import CounterDistance from '@/components/counters/CounterDistance.vue';
import CounterInfo from '@/components/counters/CounterInfo.vue';
import NText from '@/uikit/text/NText.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import { dataModule } from '@/store/data';

const columns: ITableColumn[] = [
  {
    width: '100px',
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.id' }
    },
    content: {
      path: 'id',
      component: NButtonModel,
      props: { type: 'link', align: 'left' }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.name' }
    },
    content: {
      path: 'name',
      component: function counterNameColumn(this: Vue, props, ctx) {
        const { item } = props;
        const buttonProps = { type: 'link', align: 'left', modelValue: item.name };
        const getCameraName = (v: number) => dataModule.cameraGroupsModule.items.find((item) => item.id === v)?.name || v;
        const cameraGroupNames = (item['camera_groups'] || []).map(getCameraName);
        return <div>
          <NButtonModel {...buttonProps} />
          <div>{cameraGroupNames.join(', ')}</div>
        </div>;
      },
      props: (item: any) => ({ item })
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.info' }
    },
    content: {
      component: CounterInfo,
      props: (item: any) => ({ item })
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.faces' }
    },
    content: {
      path: 'now_faces',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.bodies' }
    },
    content: {
      path: 'now_bodies',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.cars' }
    },
    content: {
      path: 'now_cars',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.distance' }
    },
    content: {
      component: CounterDistance,
      props: (item: any) => ({ item })
    }
  },
  {
    width: '100px',
    header: {
      classes: ['align-right', 'bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.active' }
    },
    content: {
      path: 'active',
      classes: ['align-right'],
      component: NSwitch
    }
  }
];

export default columns;
